@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700);

/* You can add global styles to this file, and also import other style files */
/* #GOOGLE FONT */
body:not(.menu-on-top).desktop-detected {
  min-height: auto !important;
  overflow: hidden;
}
body:not(.menu-on-top).mobile-detected {
  min-height: auto !important;
  overflow: hidden;
}
body {
  background: unset;
  background-color: #202124;
}
#content {
  padding: 20px !important;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
.page-footer {
  /* padding-left: 0px; */
  z-index: 0;
  border-top: none;
}
#logo {
  margin: 0;
  vertical-align: middle;
  display: table-cell;
  padding: 7px;
  width: 175px;
}
#logo img {
  width: auto;
  padding-left: 0;
  height: 48px;
  vertical-align: middle;
}
.setnavBackgroundImg {
  position: relative;
  z-index: 2;
  max-width: none;
  width: 90px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  display: flex;
  margin: 0 auto;
  height: 110px;
  width: 110px;
}
.col-xs-10 {
  width: 83.33333333% !important;
}
.col-xs-2 {
  width: 16.66666667% !important;
}
.col-xs-3 {
  width: 25% !important;
}
.col-xs-7 {
  width: 58.33333333% !important;
}
.col-xs-8 {
  width: 66.66666667% !important;
}
.col-xs-6 {
  width: 50% !important;
}
.col-xs-1 {
  width: 8.33333333% !important;
}
.col-xs-4 {
  width: 33.33333333% !important;
}
.col-xs-5 {
  width: 41.66666667% !important;
}
#extr-page {
  overflow-x: hidden;
}
.notification-body .unread {
  background: #1E1E1E;
}
.notification-body li span {
  background: #0C1214 !important;
}
.li-footer__copy-text {
  color: #8B91A0;
}
.registeContent {
  max-height: calc(100vh);
}
.loader-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.loader > span {
  width: 15px;
  height: 15px;
  display: block;
  background: #BEEB20;
  border-radius: 50%;
  position: relative;
  margin: 0 5px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot-1 {
  -webkit-animation: anim 1s linear 0s infinite;
          animation: anim 1s linear 0s infinite;
}
.dot-2 {
  -webkit-animation: anim 1s linear 0.25s infinite;
          animation: anim 1s linear 0.25s infinite;
}
.dot-3 {
  -webkit-animation: anim 1s linear 0.5s infinite;
          animation: anim 1s linear 0.5s infinite;
}
.dot-4 {
  -webkit-animation: anim 1s linear 0.75s infinite;
          animation: anim 1s linear 0.75s infinite;
}
@-webkit-keyframes anim {
  0% {
    top: 0;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0;
  }
}
@keyframes anim {
  0% {
    top: 0;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0;
  }
}
@media (max-width: 1161px) and (min-width: 300px) {
  #extr-page #header #logo img {
    width: 150px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 280px) {
  #header > :first-child,
aside {
    width: 150px;
  }

  #extr-page #header #logo img {
    width: 125px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
#tile1,
#tile2, #tile3 {
  width: 65px;
  height: 65px;
  cursor: pointer;
  background-repeat: no-repeat;
  /* margin-left: 25px; */
  display: flex;
  margin: 1%;
}
.logoutIcon {
  font-size: 20px;
}
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 #logo-group {
  background: unset !important;
}
.smart-style-1 .btn-header:first-child a {
  background: unset;
  border: 1px solid #e4e4e4;
}
.smart-style-1 #header {
  background-color: #202124;
}
.smart-style-1 aside {
  background-color: #171819;
}
.smart-style-1 .page-footer {
  background-color: #202124;
}
.smart-style-1 #ribbon {
  background: #707070;
}
.smart-style-1 .ajax-dropdown {
  background-color: #0C1214 !important;
}
.smart-style-1 .ajax-notifications {
  background-color: #0C1214 !important;
}
.smart-style-1 .header-dropdown-list a:hover {
  color: #ffffff !important;
}
.smart-style-1 .setnavBackgroundImg,
.smart-style-1 .setprfBackgroundImg {
  background-image: url(/assets/img/svgImages/Group348.svg);
}
.smart-style-1 .setBackgroundCompanylogoImg {
  background-image: url(/assets/img/companylogo.png);
}
.smart-style-1 #tile1 {
  background-image: url(/assets/img/invoice_orange.svg);
}
.smart-style-1 #tileItems1:hover #tile1 {
  background-image: url(/assets/img/right-circle-arrow.svg);
}
.smart-style-1 #tile2 {
  background-image: url(/assets/img/filemanager_orange.svg);
}
.smart-style-1 #tile3 {
  background-image: url(/assets/img/Contract_orange.svg);
}
.smart-style-1 #tileItems2:hover #tile2 {
  background-image: url(/assets/img/right-circle-arrow.svg);
}
.smart-style-1 #tileItems3:hover #tile3 {
  background-image: url(/assets/img/right-circle-arrow.svg);
}
.smart-style-1 .commonTile {
  border: 1px solid #b6eb00 !important;
}
.smart-style-1 .commonTile .txtSpan {
  color: #fff;
}
.smart-style-1 .commonTile:hover .txtSpan {
  color: #000 !important;
}
.smart-style-1 .dashboard_Header .dashHeadLbl {
  color: #fff;
}
.smart-style-1 .logoutIcon {
  color: #fff !important;
}
/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 .login-info {
  color: #212529;
}
.smart-style-2 aside {
  color: #fff !important;
  background-image: linear-gradient(#666666, #666666) !important;
}
.smart-style-2 #header {
  background-color: #ffffff;
}
.smart-style-2 nav ul .active > a {
  color: #202427 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.smart-style-2 .setnavBackgroundImg,
.smart-style-2 .setprfBackgroundImg {
  background-image: url(/assets/img/svgImages/Group349.svg);
}
.smart-style-2 .setBackgroundCompanylogoImg {
  background-image: url(/assets/img/companylogo.png);
}
.smart-style-2 #tile1 {
  background-image: url(/assets/img/invoice.svg);
}
.smart-style-2 #tile2 {
  background-image: url(/assets/img/filemanager.svg);
}
.smart-style-2 #tile3 {
  background-image: url(/assets/img/Contract.svg);
}
.smart-style-2 .commonTile {
  border: 1px solid #b6eb00 !important;
}
.smart-style-2 .commonTile .txtSpan {
  color: #212529;
}
.smart-style-2 .commonTile .tileItems {
  background-color: #ededed !important;
}
.smart-style-2 .ratingSection .prd_Item {
  color: #000 !important;
}
.smart-style-2 .ratingSection .rating_subscriber {
  color: #000 !important;
}
.smart-style-2 .availableDeviceList span {
  color: #000 !important;
}
.smart-style-2 .descSection, .smart-style-2 .featureLbl, .smart-style-2 .featureList, .smart-style-2 .dash_head {
  color: #000 !important;
}
.smart-style-2 .commonTile:hover .txtSpan {
  color: #000 !important;
}
.smart-style-2 .dashHeadLbl {
  color: #212529 !important;
}
.smart-style-2 #tile1:hover, .smart-style-2 #tile2:hover, .smart-style-2 #tile3:hover {
  background-image: url(/assets/img/right-circle-arrow.svg);
}
.smart-style-2 .logoutIcon {
  color: #000 !important;
}
/*
---------------------------------------------------------------------------------------
    White Dark theme style
---------------------------------------------------------------------------------------
*/
@media (max-width: 992px) {
  #content {
    max-height: calc(100vh - 130px) !important;
  }
}
@media (max-width: 576px) {
  #content {
    max-height: calc(100vh - 180px) !important;
  }
}
@charset "UTF-8";
#left-panel b.collapse-sign {
  display: none;
}
#ribbon ol.breadcrumb {
  font-size: 13px;
  font-weight: 600;
}
.fa-exclamation {
  color: red;
  font-size: x-large;
  padding: 0px 5px;
}
.dt-toolbar {
  display: none !important;
}
table {
  width: 100%;
  background-color: inherit !important;
  color: #ffffff;
  border-collapse: collapse;
}
.demo {
  position: absolute;
  top: 5px;
  right: 0;
  width: 160px;
  z-index: 902;
  background: #F1DA91;
}
.demo label {
  margin-bottom: 4 !important;
}
.demo label input[type=checkbox].checkbox + span:before {
  border: 1px solid #5E5E5E;
}
.demo label input[type=radio].radiobox + span:before {
  border: 1px solid #5E5E5E;
}
.demo label:hover input[type=checkbox].checkbox + span:before {
  border-color: #E77317;
}
.demo .checkbox {
  padding-left: 22px;
}
.demo .checkbox i {
  border-color: #7A7A7A;
}
.demo .radio {
  padding-left: 22px;
}
.demo #demo-setting + form {
  display: none;
}
.demo span {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 5px 0 0 5px;
  background: #F1DA91;
  padding: 2px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
.demo span i {
  font-size: 17px;
}
.demo span:hover i {
  color: #333 !important;
}
.demo form section {
  margin: 0 !important;
}
.demo .btn {
  font-size: 13px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
  padding: 3px 7px !important;
  height: auto !important;
}
.demo.activate {
  right: 0 !important;
  box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.2);
  padding: 5px 10px 10px;
}
.demo.activate #demo-setting + form {
  display: block;
}
.demo.activate span {
  left: -30px;
}
.demo.activate span i:before {
  content: "";
}
.demo-liveupdate-1 {
  font-size: 12px !important;
  position: absolute !important;
  left: 33px;
  top: 15px;
  display: block;
  z-index: 2;
  font-weight: 700;
  padding: 1px 61px 3px 7px;
}
.demo-btns {
  margin: 0;
  padding: 0;
  list-style: none;
}
.demo-btns li {
  display: inline-block;
  margin-bottom: 7px;
}
.demo-icon-font {
  font-size: 14px;
  margin-bottom: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.demo-icon-font:hover {
  background-color: rgba(86, 61, 124, 0.1);
  text-decoration: none;
}
.demo-modal {
  width: auto;
  max-width: 600px;
  z-index: 1;
}
.demo-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.demo-vertical-uislider {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.demo-vertical-uislider div {
  height: 200px;
  margin: 0 0 0 10px;
  display: inline-block;
}
.demo-vertical-uislider:first-child {
  margin-left: 15px;
}
.toggle-demo {
  position: absolute;
  top: 10px;
  right: -8px;
  display: block;
  width: 154px;
  height: auto;
  z-index: 99;
  opacity: 0.9;
}
.toggle-demo:hover {
  opacity: 1;
}
.toggle-demo .btn-group {
  width: 100px;
  float: left;
}
.toggle-demo span {
  display: inline-block;
  float: left;
  margin-right: 5px;
  line-height: 21px;
  color: #757575;
}
#header .menu-item {
  margin: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  cursor: pointer;
}
#header .menu-item_pading {
  padding: 0 10px;
}
#header .menu-left-item {
  padding: 0 10px;
}
#header .company-title {
  font: normal normal bold 12px/19px Open Sans;
}
#header .company-name {
  font: normal normal normal 14px/19px Open Sans;
}
#header #fullscreen .svg-icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 45px !important;
  margin-top: -3px;
}
#header .notification-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header #activity.activity-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header .collapseMenu .collaspe-menu-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 40px !important;
  height: 30px !important;
}
#header .collapseMenu .collaspe-menu-sub-svg {
  background-repeat: no-repeat;
  width: 45px !important;
  height: 45px !important;
}
#header .header-btn:hover {
  border-color: #B6EB00 !important;
  background: #B6EB00 !important;
}
#header .dropdown-icon-menu ul li .btn:hover {
  border-color: #B6EB00 !important;
  background: #B6EB00 !important;
}
.knobs-demo div {
  display: inline-block;
  margin-left: 30px;
}
.knobs-demo:first-child {
  margin-left: 0;
}
.widget-box {
  background-color: #ffffff;
  box-shadow: 10px 10px 45px 0 rgba(19, 46, 72, 0.2);
  border-radius: 5px;
  overflow: hidden;
  padding: 20px;
}
.widget-box .title-box .title .ed-icon {
  transform: translateY(-50%) scale(0.8);
  transform-origin: left center;
}
.widget-box .title-box .title .title-lbl {
  font-size: 18px;
  line-height: 18px;
  padding-left: 35px;
}
.widget-box .title-box .title .title-lbl:before {
  left: 25px;
}
.widget-box .title-box .title .title-lbl.no-icon {
  padding-left: 0;
}
.widget-box .title-box .title .title-lbl.no-icon:before {
  display: none;
}
.widget-box .icon-box {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget-box .icon-box .number {
  font-size: 30px;
}
.widget-box .icon-box .ed-icon {
  width: 30px;
  height: 30px;
}
.widget-box h3 {
  font-size: 24px;
  line-height: 24px;
}
.widget-box p {
  font-size: 18px;
  line-height: 20px;
}
.activity.ajax-dropdown:first-child {
  margin: 0;
  padding: 0;
}
.activity .ajax-notifications {
  border-bottom: none;
  border-top: none;
}
.applist-box1 .applist-row {
  padding: 0 15px;
  flex-wrap: wrap;
}
.applist-box1 .applist-row .app-box {
  text-align: center;
  padding: 20px 0px;
  font-weight: 500;
}
.applist-box1 .applist-row .app-box .module-icon {
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 100%;
}
.fixed-navigation .nav-demo-btn {
  display: none !important;
}
.minified .nav-demo-btn {
  display: none !important;
}
.smart-rtl .demo .margin-right-5 {
  margin-right: 0 !important;
}
.listFinal {
  font-size: 16px;
  padding: 5px;
}
.listFinal::before {
  content: ">";
  font-size: 20px;
  padding: 5px;
}
.minified #clearCache_text {
  display: none;
}
.minified #help_text {
  display: none;
}
.minified .userlabel {
  display: none !important;
}
.minified .body-image {
  width: 40px !important;
  height: 40px !important;
  margin: -15px 0px 10px 0px;
}
.minified .colDisplay {
  padding: 12px 0px !important;
}
.minified #clearCache .iom_btn {
  padding: 3px 13px !important;
}
.header-dropdown-list li .dropdown-toggle {
  margin-top: 6px;
}
.header-dropdown-list .dropdown-menu {
  padding: 4px;
  overflow: auto;
  max-height: 200px;
  border-radius: 10px;
}
.header-dropdown-list .dropdown-menu li a {
  border-radius: 0 !important;
  font-size: 14px;
}
.header-dropdown-list .dropdown-menu::-webkit-scrollbar {
  width: 0px;
}
.nav-demo-btn {
  display: none;
}
body:not(.menu-on-top) .nav-demo-btn {
  display: block;
  padding: 6px 5px;
  margin: 5px 10px;
  width: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 12px;
  white-space: normal;
}
.ajax-dropdown-app > :first-child {
  margin: 0 0 3px;
  padding: 0 0 9px;
}
.hidden-menu .page-footer {
  padding-left: 20px;
}
.dashHeadLocation {
  color: #ffffff;
  display: flex;
  list-style: none;
  padding: 0;
}
.listitems {
  font-size: 24px;
}
.main-nav {
  text-align: right;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #E2E2E2;
  opacity: 1;
  padding-top: 11px;
}
.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0;
  padding-right: 10px;
}
#content {
  background: #1E1E1E !important;
}
.footerTextColor {
  color: #8B91A0 !important;
}
.theme-header-color {
  color: #E2E2E2 !important;
}
.theme-text-color {
  color: #E2E2E2 !important;
}
.header-menu {
  font: normal normal normal 14px/19px Open Sans;
}
.notification-body > li {
  border: 1px solid #e3e3e3 !important;
}
.flag.flag-en {
  background-position: -144px -154px;
}
#left-panel {
  position: absolute;
  z-index: 904;
  padding-top: 10px;
  margin-top: 60px;
}
#left-panel nav ul li.active > a:before {
  right: -19px;
  content: none !important;
}
.font-size-14 {
  font-size: 16px !important;
}
.notification-body .subject {
  color: #7A7A7A !important;
}
.iombackground {
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh !important;
  background: #C04848;
  /* fallback for old browsers */
  background: linear-gradient(rgba(102, 102, 102, 0.9), rgba(102, 102, 102, 0.9)), url(/assets/img/register.webp);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(rgba(102, 102, 102, 0.9), rgba(102, 102, 102, 0.9)), url(/assets/img/register.webp);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
}
.onboardingbackground {
  background-repeat: no-repeat;
  background-position: center;
  height: 1000px !important;
  width: 100%;
  background: #C04848;
  /* fallback for old browsers */
  background: linear-gradient(rgba(102, 102, 102, 0.9)), url(/assets/img/onboardingbg.webp);
  /* Chrome 10-25, Safari 5.1-6 */
  background: url(/assets/img/onboardingbg.webp);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
}
.iombackgrounds {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.userAccountSvg {
  background-repeat: no-repeat;
  background-position: center;
  width: 49px !important;
  height: 45px !important;
  background-image: url('user.23211b4b22ee8a248707.png');
}
.toolsvg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('tools.9b60c16d7739a9ac429d.png');
}
.support-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('support.f7bfa814958611603cb8.png');
}
.conection-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('conections.7e1bc3b862fe00171b61.png');
}
.data-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('data2x.530f077fc89937c875d0.png');
}
.terms-and-conditions-container h1.service-heading,
.privacy-policy-container h1.service-heading,
.legal-notice-container h1.service-heading {
  padding: 20px 0 0 0;
  margin-bottom: 20px;
  color: #000;
  /* font: 700 36px/47px "Raleway",sans-serif; */
  position: relative;
}
.terms-and-conditions-container .agb-cont ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}
.terms-and-conditions-container .agb-cont ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}
.terms-and-conditions-container .agb-cont ol li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.75em;
  font-size: 17px;
  line-height: 47px;
  color: #474544;
  /* font-family: "Ubuntu"; */
  font-weight: 700;
  position: relative;
}
.darktheme.smart-style-5 .terms-and-conditions-container .agb-cont ol li {
  color: #ffffff;
}
.terms-and-conditions-container .agb-cont ol li ol {
  margin-top: 1rem;
}
.terms-and-conditions-container .agb-cont li li {
  margin: 10px 0;
  color: #717171;
  /* font-family: "Raleway",sans-serif; */
  font-size: 1.5rem;
  line-height: 24px;
  font-weight: 400;
}
.terms-and-conditions-container .agb-cont li li:before {
  content: counters(item, ".") " ";
  padding-left: 0;
}
.terms-and-conditions-container .agb-cont li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
  font-weight: 600;
  padding-left: 15px;
}
.terms-and-conditions-container .agb-cont ol li:after {
  content: "";
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.terms-and-conditions-container .agb-cont ol li li:after {
  display: none;
}
.terms-and-conditions-container .service-info-text a {
  color: #1570b8;
  font-weight: 700;
  transition: all 0.4s;
}
.terms-and-conditions-container .service-info-text a:hover {
  color: #000;
}
.terms-and-conditions-container,
.legal-notice-container {
  padding-right: 15px;
  padding-left: 18px;
  font-size: 1.5rem;
  height: 100vh;
  overflow: auto;
}
.privacy-policy-container {
  /* font-family: "Raleway",sans-serif; */
  line-height: 1.75rem;
  font-weight: 400;
  color: #343e47;
  display: block;
  padding: 0px 15px 0px;
  padding-right: 15px;
  padding-left: 18px;
  font-size: 1.5rem;
  height: 930px;
  overflow: auto;
}
.privacy-policy-container li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.privacy-policy-container h4 {
  font-size: 17px;
  line-height: 32px;
  font-weight: 700;
  color: #474544;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 48px;
  display: flex;
  align-items: center;
}
.darktheme.smart-style-5 .privacy-policy-container h4 {
  color: #ffffff;
}
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text p {
  color: #474544;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 24px;
}
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text p span,
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text ul li span {
  line-height: 24px;
}
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text .well {
  padding: 15px;
}
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text .well p {
  margin: 5px 0;
  padding-left: 0;
  padding-right: 0;
}
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text a {
  color: #1570b8;
  font-weight: 700;
  transition: all 0.4s;
}
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text a:hover {
  color: #000;
}
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text ul {
  margin-left: 15px;
  margin-right: 15px;
}
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text ul li {
  position: relative;
  padding-left: 1.3rem;
  margin-top: 5px;
  color: #717171;
  line-height: 24px;
}
.legal-notice-container .service-info-text h4 {
  font-size: 17px;
  line-height: 32px;
  color: #474544;
  font-weight: 700;
  min-height: 48px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
}
.darktheme.smart-style-5 .legal-notice-container .service-info-text h4 {
  color: #ffffff;
}
.legal-notice-container .service-info-text p {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 0 15px;
}
.legal-notice-container .service-info-text address {
  margin-bottom: 0;
  margin-left: 15px;
}
.legal-notice-container .service-info-text .well.auth-rep p {
  margin-bottom: 0;
  font-weight: 700;
}
.legal-notice-container .service-info-text .well p {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.scroll-content::-webkit-scrollbar {
  display: none;
}
.scroll-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.mobile-view-activated::-webkit-scrollbar {
  display: none;
}
.mobile-view-activated {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.MessageBoxButtonSection button {
  border-radius: 15px;
  background: transparent;
  border: 2px solid #B6EB00 !important;
  font-size: 13px;
  padding: 3px 15px;
  color: #ffffff !important;
  font-weight: 400 !important;
}
.MessageBoxButtonSection button:hover {
  background: #B6EB00 !important;
  color: #000 !important;
}
.minified nav > ul > li > a > .menu-item-parent {
  top: -1px;
  height: 41px;
}
.ui-datepicker select.ui-datepicker-month {
  margin: 0 2px;
  width: 47%;
}
.ui-datepicker select.ui-datepicker-year {
  margin: 0 2px;
  width: 47%;
}
.ui-datepicker .ui-datepicker-next span {
  background: none !important;
}
.ui-datepicker .ui-datepicker-prev span {
  background: none !important;
}
.ui-datepicker-prev span {
  background-image: none !important;
}
.ui-datepicker-next span {
  background-image: none !important;
}
.ui-datepicker-prev:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.ui-datepicker-next:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.MessageBoxMiddle .MsgTitle {
  letter-spacing: -1px;
  font-size: 24px;
  font-weight: 300;
  margin-left: -28px;
}
.editButton {
  font-size: 37px;
  text-align: center;
  font: normal normal normal 30px/41px Open Sans;
  letter-spacing: -0.3px;
  border: 2px solid #B6EB00;
  color: #202528;
  border-radius: 19px;
  font-size: 13px;
  top: 55px;
  position: relative;
  float: right;
  width: 155px;
  height: 34px;
  right: 220px;
}
.editButton:hover {
  background: #B6EB00 !important;
  color: #0C1214 !important;
}
.itemEditButton {
  font-size: 37px;
  text-align: center;
  font: normal normal normal 30px/20px Open Sans;
  letter-spacing: -0.3px;
  border: 2px solid #B6EB00;
  border-radius: 19px;
  font-size: 13px;
  padding: 3px 15px;
}
.itemEditButton:hover {
  background: #B6EB00 !important;
  color: #0C1214 !important;
  border-color: #B6EB00 !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}
.itemEditButton:active {
  background: #B6EB00 !important;
  border-color: #B6EB00 !important;
}
.modalBtn {
  /* max-width: 150px; */
  /* width: 110px; */
  margin-top: 10px;
  border: 2px solid #B6EB00;
  padding: 3px 15px;
}
button[disabled],
html input[disabled] {
  cursor: not-allowed;
}
.sub-btn {
  font-size: 14px;
  border-radius: 15px;
  background-color: transparent !important;
  padding: 3px 15px;
  margin-right: -13px;
}
.text-danger {
  color: #dc3545 !important;
  font-size: 15px;
}
@media (max-width: 979px) {
  .page-footer {
    padding: 5px 14px 0;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .flag + span {
    max-width: 100% !important;
  }
}
@media (max-width: 992px) {
  #fullscreen .svg-icon {
    width: 40px !important;
  }

  #header #activity.activity-svg {
    width: 40px !important;
  }

  #header .notification-svg {
    width: 40px !important;
  }
}
@media (max-width: 576px) and (min-width: 300px) {
  .page-footer {
    bottom: unset !important;
  }
}
.minified .profile-image-box {
  display: none;
}
.minified #left-panel ul {
  margin-top: -28px !important;
}
.smart-style-2.minified #left-panel ul {
  margin-top: -68px !important;
}
.dt-toolbar-footer {
  padding: 5px 0px;
}
.blacktooltip {
  background-color: #000;
  border: none !important;
}
.badge-disable-iom {
  cursor: no-drop !important;
  opacity: 0.5;
}
.iom_btn {
  border-radius: 15px;
  background: transparent;
  border: 2px solid #B6EB00;
  font-size: 13px;
  padding: 3px 15px;
  line-height: 20px;
  font-weight: 400;
}
.iom_btn:hover {
  border-color: #B6EB00 !important;
  background: #B6EB00 !important;
  color: #171717 !important;
}
.g_id_signin {
  display: none !important;
}
.page-footer {
  height: unset !important;
}
#logo-group {
  text-align: center !important;
  height: 57px !important;
}
/*
---------------------------------------------------------------------------------------
  Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 body {
  background-color: #1E1E1E !important;
}
.smart-style-1 #ribbon .breadcrumb li:last-child {
  color: #000 !important;
}
.smart-style-1 .breadcrumb > li + li:before {
  color: #000;
}
.smart-style-1 .auth-text-color {
  color: #0D0F12 !important;
}
.smart-style-1 #left-panel nav ul li {
  border-bottom: 1px solid #B6EB00;
}
.smart-style-1 #left-panel nav ul li:hover {
  background: #B6EB00 !important;
  color: #000 !important;
  border-bottom: 1px solid #3B3B3B33 !important;
}
.smart-style-1 #left-panel nav ul li.active {
  background-color: #B6EB00 !important;
}
.smart-style-1 #left-panel nav ul li.active a {
  color: #202427 !important;
}
.smart-style-1 #ribbon .breadcrumb li {
  color: #8B91A0;
}
.smart-style-1 #ribbon .breadcrumb li:last-child {
  color: #ffffff !important;
}
.smart-style-1 .theme-header-text-color {
  color: #ffffff !important;
}
.smart-style-1 .theme-subtext-color {
  color: #959595 !important;
}
.smart-style-1 #content {
  background: #202124 !important;
}
.smart-style-1 .dashabord .jarviswidget div {
  background-color: #0C1214 !important;
  border: 1px solid #3B424C !important;
}
.smart-style-1 .theme-icon {
  color: #ffffff !important;
}
.smart-style-1 .theme-text-color {
  color: #E2E2E2 !important;
}
.smart-style-1 .ajax-dropdown {
  border: 1px solid #313840 !important;
}
.smart-style-1 .notification-body li {
  border: 1px solid #252b31 !important;
}
.smart-style-1 .dropdown-menu {
  background-color: #0C1214;
  border: 1px solid #3B424C;
}
.smart-style-1 .dropdown-menu li a {
  color: #ffffff;
}
.smart-style-1 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #1E1E1E !important;
}
.smart-style-1 .header-dropdown-list .dropdown-menu .active a {
  background-color: #1E1E1E !important;
}
.smart-style-1 .applicationHead {
  height: 34px;
  background: #202124;
  color: #ffffff;
  opacity: 1;
  border: unset;
  margin-left: 5px;
  margin-right: -5px;
}
.smart-style-1 #fullscreen .svg-icon {
  background-image: url('Path 222.737b381f1c9fbc85a137.svg');
}
.smart-style-1 #header .notification-svg {
  background-image: url('39-Notification.4feddd112452285fe534.svg');
}
.smart-style-1 #header #activity.activity-svg {
  background-image: url('Page-1.9f2dc138f1ca7885f995.svg');
}
.smart-style-1 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.c485c714dd85f9b4f52d.svg');
}
.smart-style-1 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
}
.smart-style-1 #header .collapseMenu:hover {
  background-color: #0D0F12;
}
.smart-style-1 #header .header-btn {
  background-color: #1E1E1E;
}
.smart-style-1 #header .header-btn-list a {
  background-color: #1E1E1E !important;
}
.smart-style-1 #header .menu-item:hover {
  background-color: #666666;
}
.smart-style-1 #header .theme-text-color {
  color: #ffffff !important;
}
.smart-style-1 #header .menu-item.no-hover:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header .menu-left-item {
  border-left: unset !important;
}
.smart-style-1 .jarviswidget-color-darken header {
  background: #171819 !important;
  border: none;
}
.smart-style-1 .spanBoderBottom {
  border-bottom: 1px solid #3B424C !important;
}
.smart-style-1 .modalBodyBgColor {
  background-color: #171819 !important;
}
.smart-style-1 .applicationHeadModal {
  background: #0D0F12 !important;
}
.smart-style-1 .theme-fade-color {
  color: #959595 !important;
}
.smart-style-1 .subList:not(:last-child) {
  border-bottom: 1px solid #202124 !important;
}
.smart-style-1 .border {
  border: 1px solid #666666 !important;
  border-radius: 10px;
}
.smart-style-1 .userProfile .applicationBackGround {
  background-color: #202124 !important;
  margin-right: -5px;
}
.smart-style-1 .menuSelected {
  background-color: #666666;
}
.smart-style-1 .jarviswidget > div {
  border: none;
}
.smart-style-1 .border-line {
  border: 1px solid #3B424C;
  background-color: #0C1214 !important;
  opacity: 1;
}
.smart-style-1 nav ul li a {
  color: #ffffff !important;
  font-size: 14px !important;
}
.smart-style-1 nav ul li a:focus, .smart-style-1 nav ul li a:hover {
  background: #B6EB00 !important;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .body-images {
  border-radius: 100%;
}
.smart-style-1 .editButton {
  background-color: #202124;
}
.smart-style-1 .itemEditButton {
  background-color: #202124;
  color: #ffffff !important;
}
.smart-style-1 .itemEditButton:hover {
  background: #B6EB00 !important;
  color: #0C1214 !important;
}
.smart-style-1 .profileDeatils {
  border: 1px solid #666666;
  margin-top: -36px;
  background: #202124 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-left: 24px;
}
.smart-style-1 .modalBtn {
  color: #ffffff !important;
}
.smart-style-1 .profileModalBtn {
  padding: 10px;
  background-color: transparent;
  border: 2px solid #B6EB00 !important;
  color: #ffffff !important;
  border-radius: 19px;
  font-size: 13px;
}
.smart-style-1 .profileModalBtn:hover {
  background: #B6EB00 !important;
  color: #0C1214 !important;
}
.smart-style-1 .profileEditTextBox {
  background: #202124 0% 0% no-repeat padding-box !important;
}
.smart-style-1 .smallLang {
  display: block !important;
}
.smart-style-1 .smallLang .dropdown-toggle::after {
  color: #000 !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-toggle .language-text {
  color: #212529 !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-menu {
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #EDEDED !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-menu .active a {
  background-color: #EDEDED !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-menu .dropdown-item {
  color: #212529 !important;
}
.smart-style-1 .ui-datepicker {
  background: #202125 !important;
  border-radius: 5px !important;
}
.smart-style-1 .ui-datepicker .ui-datepicker-header {
  background-color: #202125;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-month {
  border-color: #303030;
  background: #202125;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-year {
  border-color: #303030;
  background: #202125;
}
.smart-style-1 .ui-datepicker th {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker td .ui-state-default {
  color: #ffffff;
}
.smart-style-1 .ui-datepicker td .ui-state-active {
  background: #707070;
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-header a {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-month {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-year {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-prev:before {
  background: #202125;
}
.smart-style-1 .ui-datepicker-next:before {
  background: #202125;
}
.smart-style-1 .NextconfirmLabels {
  color: #ffffff;
  border: 1px solid #666666;
}
.smart-style-1 .reMark {
  color: #ffffff;
  border: 1px solid #666666;
}
.smart-style-1 #spnFilePath {
  color: #ffffff !important;
}
.smart-style-1 .sub-btn {
  border: 2px solid #B6EB00 !important;
  color: #ffffff !important;
  font-weight: bold;
}
.smart-style-1 .sub-btn:hover {
  background: #B6EB00 !important;
  color: #0C1214 !important;
}
.smart-style-1 .theme-fade-in {
  color: #ffffff;
}
.smart-style-1 .successDiv {
  background-color: #171819;
}
.smart-style-1 .theme-content-color {
  color: #ffffff;
}
.smart-style-1 .successchangepassDiv {
  background-color: #171819;
}
.smart-style-1 .MessageBoxButtonSection button {
  color: #ffffff;
}
.smart-style-1 .payBody,
.smart-style-1 .modal_header {
  color: #ffffff;
}
.smart-style-1 .NextconfirmLabel {
  color: #ffffff;
}
.smart-style-1 .devOptions {
  background-color: #171819;
  color: #fff;
}
.smart-style-1 .devOptions textarea {
  background-color: #171819;
}
.smart-style-1 #header.colorChangeTest .header-btn {
  background-color: transparent;
  box-shadow: none;
}
.smart-style-1 #header.colorChangeTest .header-btn:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header.colorChangeTest .dropdown-icon-menu:hover .header-btn {
  background-color: #1E1E1E;
}
.smart-style-1 .modal-body {
  background-color: #171717 !important;
}
.smart-style-1 .modal-content {
  background-color: #171717 !important;
  border: 1px solid #666666;
  color: #ffffff;
  border-radius: 10px;
}
.smart-style-1 .modal-footer {
  padding: 20px 0px 0px 0px;
  border-top: none;
}
.smart-style-1 .btn-primary {
  background: transparent !important;
  border: 2px solid #B7EB00;
  color: #ffffff !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-1 .btn-primary:hover {
  background: #B7EB00 !important;
  color: #171717 !important;
  border: 2px solid #B7EB00;
}
.smart-style-1 .navFooter .iom_btn {
  color: #ffffff !important;
}
.smart-style-1 .navFooter .iom_btn:hover {
  color: #000 !important;
}
.smart-style-1 app-login .iom_btn {
  color: #111 !important;
}
.smart-style-1 .iom_btn {
  color: #fff !important;
}
.smart-style-1 .iom_btn:hover {
  color: #666666 !important;
}
/*
---------------------------------------------------------------------------------------
    End   Dark theme style
---------------------------------------------------------------------------------------
*/
/*
---------------------------------------------------------------------------------------
    White theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 .applicationHead {
  height: 34px;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #000 !important;
  opacity: 1;
  border: unset;
  margin-left: 5px;
  margin-right: -5px;
}
.smart-style-2 #left-panel {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}
.smart-style-2 #left-panel nav ul li {
  border-bottom: 1px solid #B6EB00;
}
.smart-style-2 #left-panel nav ul li:hover {
  background: #B6EB00 !important;
  color: #000 !important;
  border: 1px solid #3B3B3B33;
}
.smart-style-2 #left-panel nav ul li.active {
  background-color: #B6EB00 !important;
}
.smart-style-2 #left-panel nav ul li.active a:before {
  content: unset !important;
}
.smart-style-2 nav ul li.active a:before {
  color: #202427;
}
.smart-style-2 nav ul li a {
  color: #ffffff !important;
  padding: 12px 17px 12px 16px !important;
}
.smart-style-2 nav ul li a i {
  font-size: 17px !important;
  vertical-align: 0 !important;
}
.smart-style-2 nav ul li.active > a:before {
  content: unset !important;
}
.smart-style-2 .notification-body .unread {
  background-color: #EDEDED;
}
.smart-style-2 .notification-body .from {
  color: #4C4F53 !important;
}
.smart-style-2 .notification-body .msg-body {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body .activityTime {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body li span {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .ajax-dropdown {
  border: 1px solid #d9d9d9 !important;
}
.smart-style-2 #fullscreen .svg-icon {
  background-image: url('Path222.faed6ac4229dbddce176.svg');
}
.smart-style-2 #header .notification-svg {
  background-image: url('39-Notification.a91267442bae57b87b90.svg');
}
.smart-style-2 #header #activity.activity-svg {
  background-image: url('Page-1.06157606d3196914b46c.svg');
}
.smart-style-2 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.55a5f2da498c377ed45f.svg');
}
.smart-style-2 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
}
.smart-style-2 #header .collapseMenu:hover {
  background-color: #CCCCCC;
}
.smart-style-2 #header .header-btn {
  box-shadow: none;
}
.smart-style-2 #header .header-btn-list a {
  box-shadow: none;
}
.smart-style-2 #header .menu-item:not(:last-child) {
  border: unset !important;
}
.smart-style-2 #header .menu-item .webComponetsBorder {
  border: none;
}
.smart-style-2 #header .menu-item:hover {
  background-color: #CCCCCC;
}
.smart-style-2 #header .menu-item .webComponetsHoverColor:hover {
  background-color: #CCCCCC;
}
.smart-style-2 #header .menu-item.no-hover:hover {
  background-color: #EDEDED;
}
.smart-style-2 .header-dropdown-list a.dropdown-toggle {
  color: #4C4F53 !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #EDEDED !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu .active a {
  background-color: #EDEDED !important;
}
.smart-style-2 .theme-reverse-text-color {
  color: #0C1214 !important;
  color: #ffffff !important;
}
.smart-style-2 .theme-icon {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-text-color {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-header-text-color {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-subtext-color {
  color: #7A7A7A !important;
}
.smart-style-2 .dashboard .jarviswidget div {
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .profileBackground {
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 #ribbon .breadcrumb li {
  color: #69737A;
}
.smart-style-2 #ribbon .breadcrumb li:last-child {
  color: #4C4F53 !important;
}
.smart-style-2 .userProfile .applicationBackGround {
  background-color: #ffffff !important;
  margin-right: -5px;
}
.smart-style-2 .userProfile .theme-text-color {
  color: #202528 !important;
  font-weight: 700;
}
.smart-style-2 .border {
  border: 1px solid #CCCCCC !important;
  border-radius: 10px;
}
.smart-style-2 .subList:not(:last-child) {
  border-bottom: 1px solid #ffffff !important;
}
.smart-style-2 .theme-fade-color {
  color: #7A7A7A !important;
}
.smart-style-2 .applicationHeadModal {
  background: #353D4B !important;
}
.smart-style-2 .modalBodyBgColor {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.smart-style-2 .spanBoderBottom {
  border-bottom: 1px solid #CCCCCC !important;
}
.smart-style-2 .jarviswidget-color-darken header {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: none;
}
.smart-style-2 .theme-bg-color {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .theme-header-color {
  color: #4C4F53 !important;
}
.smart-style-2 .footerTextColor {
  color: #ffffff !important;
}
.smart-style-2 .login-info {
  color: #212529 !important;
}
.smart-style-2 #content {
  background: #ffffff !important;
}
.smart-style-2 .auth-text-color {
  color: #CCCCCC !important;
}
.smart-style-2 .menuIconsOption img {
  filter: brightness(100) invert(1);
}
.smart-style-2 .menuSelected {
  background-color: #CCCCCC;
}
.smart-style-2 nav ul li.active > a:before {
  content: unset !important;
}
.smart-style-2 .dropdown-icon-menu > ul > li .btn,
.smart-style-2 .header-btn {
  background: none;
}
.smart-style-2 .body-images {
  border-radius: 100%;
}
.smart-style-2 .profileDeatils {
  border: 1px solid #CCCCCC !important;
  margin-top: -36px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: 24px;
}
.smart-style-2 .theme-text-color {
  color: #202528;
}
.smart-style-2 .jarviswidget > div {
  border-right-color: #ffffff !important;
  border-bottom-color: #ffffff !important;
  border-left-color: #ffffff !important;
}
.smart-style-2 .editButton {
  background-color: #ffffff;
}
.smart-style-2 .itemEditButton {
  background-color: #ffffff;
  color: #202528;
  border-color: #B6EB00 !important;
}
.smart-style-2 .itemEditButton:active {
  background: #B6EB00 !important;
  border-color: #B6EB00 !important;
}
.smart-style-2 .modalBtn {
  color: #000 !important;
}
.smart-style-2 .profileModalBtn {
  padding: 10px;
  background-color: #ffffff !important;
  border: 2px solid #B6EB00 !important;
  color: #000 !important;
  border-radius: 19px;
  font-size: 13px;
}
.smart-style-2 .profileModalBtn:hover {
  background: #B6EB00 !important;
  color: #0C1214 !important;
}
.smart-style-2 .profileEditTextBox {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .NextconfirmLabels {
  color: #171717;
  border: 1px solid #ced4da;
}
.smart-style-2 .reMark {
  color: #171717;
  border: 1px solid #ced4da;
}
.smart-style-2 #spnFilePath {
  color: #000 !important;
}
.smart-style-2 .darkHeaderIcon,
.smart-style-2 .lightHeaderIcon {
  color: #171717 !important;
}
.smart-style-2 .sub-btn {
  border: 2px solid #B6EB00 !important;
  color: #000 !important;
  font-weight: bold;
}
.smart-style-2 .sub-btn:hover {
  background: #B6EB00 !important;
  color: #0C1214 !important;
}
.smart-style-2 .theme-fade-in {
  color: #000;
}
.smart-style-2 .heaerText,
.smart-style-2 .headerimgTxt {
  color: #0C1214 !important;
}
.smart-style-2 .successDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .theme-content-color {
  color: #666666;
}
.smart-style-2 .successchangepassDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .MessageBoxButtonSection button {
  color: #171717 !important;
}
.smart-style-2 .NextconfirmLabel {
  color: #171717;
}
.smart-style-2 .devOptions {
  background-color: #f5f5f5;
  color: #171819;
}
.smart-style-2 .devOptions textarea {
  background-color: #f5f5f5;
}
.smart-style-2 .btn-primary {
  background: #fff !important;
  border: 2px solid #B7EB00;
  color: #202124 !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-2 .navFooter .iom_btn {
  color: #ffffff !important;
}
.smart-style-2 .navFooter .iom_btn:hover {
  color: #202427 !important;
}
.smart-style-2 .iom_btn {
  color: #202427 !important;
}
.smart-style-2 .iom_btn:hover {
  color: #202427 !important;
}
.smart-style-2 .nav_btn {
  color: #fff !important;
}
.smart-style-2 .nav_btn:hover {
  color: #202427 !important;
}
/*
---------------------------------------------------------------------------------------
    End White theme style
---------------------------------------------------------------------------------------
*/
#header.colorChangeTest {
  background-color: rgba(183, 235, 0, 0.4) !important;
}
.cookie-text {
  font-size: 12px;
}
.show-consent {
  display: flex;
  display: -webkit-flex;
}
.cookie-consent {
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #d6d6d6;
  background-color: #3c404d;
  padding: 0.6em 1.8em;
  z-index: 9999;
  padding: 10px 0 0 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.consent-btn {
  color: #000;
  flex: 1 0 130px;
  background-color: #B6EB00;
  border-color: transparent;
  padding: 0.2em 0.5em;
  font-size: 1.2em;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-weight: bolder;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  min-height: 20px;
  margin: 0 30px 10px 10px;
  max-width: 100px;
}
a {
  color: #B6EB00;
  text-decoration: none;
}
a:hover {
  color: #B6EB00;
}
.colorChangeTest #header {
  background-color: #667b1d !important;
  /* Override the theme-based color */
  background-image: none !important;
}
.colorChangeTest.smart-style-2 .theme-header-text-color {
  color: #fff !important;
}
.colorChangeTest.smart-style-2 .logoutIcon {
  color: #fff !important;
}
.colorChangeTest.smart-style-2 .header-dropdown-list a.dropdown-toggle {
  color: #fff !important;
}
.colorChangeTest.smart-style-2 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.c485c714dd85f9b4f52d.svg');
}
.colorChangeTest.smart-style-2 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
}
.colorChangeTest.smart-style-2 #header .collapseMenu:hover {
  background-color: #0D0F12;
}
.colorChangeTest.smart-style-2 #header #fullscreen .svg-icon {
  background-image: url('Path 222.737b381f1c9fbc85a137.svg');
}
.colorChangeTest.smart-style-2 #header .menu-item:hover {
  background-color: #666464 !important;
}
.colorChangeTest.smart-style-2 #header .header-btn {
  background-color: #1E1E1E !important;
}
.colorChangeTest.smart-style-2 #header .header-btn:hover {
  border-color: #1E1E1E !important;
}
.colorChangeTest.smart-style-2 .darkHeaderIcon,
.colorChangeTest.smart-style-2 .lightHeaderIcon {
  color: #171717 !important;
}
